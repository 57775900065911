import { Injectable, signal } from '@angular/core';

import { GridField, GridView } from '../types/grid-field';
import { formatDate } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class GridPaginationService {
  readonly selectViewIds = signal(new Map<string, string>());

  convertFilterToOdataFilter(paginationFilter: Map<string, any>): string {
    let filter = '';
    let i = 0;

    paginationFilter.forEach((value, key) => {
      const operation = this.switchFilterOperation(value.operation);

      if (value.value) {
        let parsedValue: any = '';

        switch (value.type) {
          case 'string':
            parsedValue = `'*${value.value.trim()}*'`;
            break;
          case 'number':
            parsedValue = value.value;
            break;
          case 'date':
            parsedValue = formatDate(value.value, 'yyyy-MM-dd', 'en-US', 'UTC');
            break;

          default:
            break;
        }

        if (i > 0) filter += ' and ';
        operation.type === 'function'
          ? (filter += `${operation.value}(${this.upperCaseFirstLetter(
              key
            )}, ${parsedValue})`)
          : (filter += `${
              value.type === 'string'
                ? `tolower(${this.upperCaseFirstLetter(key)})`
                : this.upperCaseFirstLetter(key)
            } ${operation.value} ${parsedValue}`);

        // Only increment for valid filters
        i++;
      }
    });
    return filter;
  }

  getDefaultFilterOperation(type: GridField<any>['dataType']): string {
    switch (type) {
      case 'number':
        return '=';
      case 'date':
        return '=';
      case 'boolean':
        return '=';
      default:
        return '=';
    }
  }

  generateDefaultView(cacheId: string) {
    const views: GridView[] = [
      {
        id: 'default',
        name: 'All',
        fieldFilters: new Map(),
        sort: {
          field: null,
          desc: false,
        },
      },
    ];

    this.setViews(cacheId, views);

    return views;
  }

  getViews(cacheId: string): GridView[] {
    let viewString = localStorage.getItem('views.' + cacheId);
    if (!viewString) return this.generateDefaultView(cacheId);

    return JSON.parse(viewString || '[]', this.mapReviver);
  }

  setViews(cacheId: string, views: GridView[]): void {
    localStorage.setItem(
      'views.' + cacheId,
      JSON.stringify(views, this.mapReplacer)
    );
  }

  addView(cacheId: string, view: GridView) {
    const views = this.getViews(cacheId);

    view.id = Math.random().toString(36).substring(2, 15);

    views.push(view);
    this.setViews(cacheId, views);

    return view;
  }

  updateView(cacheId: string, view: GridView): void {
    const views = this.getViews(cacheId);
    const index = views.findIndex((v) => v.id === view.id);
    views[index] = view;
    this.setViews(cacheId, views);
  }

  deleteView(cacheId: string, viewId: string): void {
    const views = this.getViews(cacheId);
    const index = views.findIndex((view) => view.id === viewId);
    views.splice(index, 1);
    this.setViews(cacheId, views);
  }

  compareViews(view1: Partial<GridView>, view2: Partial<GridView>): boolean {
    // We can add more properties to compare here
    return (
      JSON.stringify(
        {
          sort: view1.sort,
          fieldFilters: view1.fieldFilters,
        },
        this.mapReplacer
      ) ===
      JSON.stringify(
        {
          sort: view2.sort,
          fieldFilters: view2.fieldFilters,
        },
        this.mapReplacer
      )
    );
  }

  private mapReplacer(key: string, value: Map<any, any>) {
    if (value instanceof Map) {
      return {
        dataType: 'Map',
        value: Array.from(value.entries()),
      };
    } else {
      return value;
    }
  }

  private mapReviver(key: string, value: any) {
    if (typeof value === 'object' && value !== null) {
      if (value.dataType === 'Map') {
        return new Map(value.value);
      }
    }
    return value;
  }

  private switchFilterOperation(operation: string): {
    value: string;
    type: 'function' | 'operator';
  } {
    switch (operation) {
      case 'contains':
        return { value: 'contains', type: 'function' };
      case 'startswith':
        return { value: 'startswith', type: 'function' };
      case 'endswith':
        return { value: 'endswith', type: 'function' };
      case '=':
        return { value: 'eq', type: 'operator' };
      case '<>':
        return { value: 'ne', type: 'operator' };
      case '>':
        return { value: 'gt', type: 'operator' };
      case '>=':
        return { value: 'ge', type: 'operator' };
      case '<':
        return { value: 'lt', type: 'operator' };
      case '<=':
        return { value: 'le', type: 'operator' };
      default:
        return { value: 'eq', type: 'operator' };
    }
  }

  private upperCaseFirstLetter(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
}
